<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" class="login-form">
      <div class="login-form-title">Login</div>
      <el-form-item label="Username" prop="username">
        <el-input v-model="loginForm.username" placeholder="Enter your username"></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <el-input type="password" v-model="loginForm.password" placeholder="Enter your password"></el-input>
      </el-form-item>
	  <!-- 添加 Remember Me 单选框 -->
		<el-form-item label="Remember Me">
		  <el-checkbox v-model="rememberMe">记住我</el-checkbox>
		</el-form-item>
      <el-form-item>
        <el-button type="primary" class="login-form-button" @click="login">Login</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loginForm: {
        username: this.getStoredUsername(), // 从本地存储获取用户名
        password: '', // 密码不直接存储，但可以在表单加载时尝试填充（可选）
      },
      rememberMe: false, // 是否记住我，默认为false
    };
  },
  created() {
    if (this.rememberMe && this.getLoginInfo()) {
      // 如果勾选了记住我并且本地存储中有账号信息，则尝试填充密码
      this.loginForm.password = this.getLoginInfo().password; // 注意：此操作可能带来安全风险，谨慎使用
    }
  },
  methods: {
    login() {
      // 登录接口
	const url = `${this.$apiUrl}login?username=${encodeURIComponent(this.loginForm.username)}&password=${encodeURIComponent(this.loginForm.password)}`;

	 axios.get(url)
	     .then(response => {
	       const isLoginSuccessful = response.data; // 如果API直接返回布尔值
	       // 或者
	       // const isLoginSuccessful = response.data.success; // 如果API返回{ success: true/false }
	       
	       if (typeof isLoginSuccessful === 'boolean') {
	         if (isLoginSuccessful) {
	           // 登录成功，执行相关操作
	           this.saveLoginInfo();
	           this.$router.push('/video');
	         } else {
	           // 登录失败，提示错误信息
	           this.$message.error('用户名或密码错误');
	         }
	       } else {
	         // 处理非预期的响应格式
	         console.error('Unexpected response format from the server.');
	       }
	     })
	     .catch(error => {
	       console.error('Failed to fetch video URL:', error);
	       this.$message.error('网络错误或登录失败');
	     });

    },
    
    // 保存登录信息到本地存储
    saveLoginInfo() {
      sessionStorage.setItem('loginInfo', JSON.stringify({ 
        username: this.loginForm.username, 
        password: this.loginForm.password 
      }));
    },

    // 获取本地存储中的登录信息
    getLoginInfo() {
      const storedLoginInfo = sessionStorage.getItem('loginInfo');
      return storedLoginInfo ? JSON.parse(storedLoginInfo) : null;
    },

    // 从本地存储获取用户名
    getStoredUsername() {
      const storedLoginInfo = this.getLoginInfo();
      return storedLoginInfo ? storedLoginInfo.username : '';
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

.login-form-title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}

.login-form-button {
  width: 100%;
}
</style>
