<!-- RaceItem.vue -->
<template>
  <div>
	  <div class="race-item left" @click="showVideo(raceItem)">
	    <p>{{ raceItem.track }}</p>
	    <p>Race {{ raceItem.race }} MTP {{ raceItem.mtp }}</p>
	    <p>{{ raceItem.custdistime }}</p>
	  </div>
  </div>
  
</template>

<script>
import axios from 'axios';

export default {
  name: 'RaceItem',
  props: {
    raceItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isFullVideoVisible: false,
      videoUrl: ''
    };
  },
  methods: {
    async showVideo(item) {
      this.isFullVideoVisible = true;

      const timestamp = Date.now();
      const num = Math.floor(Math.random() * 10 + 1);
      const shopVideoUrl = `url=https://online.rtn.tv/mobileserver/index.php/rtnserver/get_meta3&stream=${item.stream}&staticspeed=0&type=live&allow=1&cust=497&hdallow=1&format=2`;

      const url = this.$apiUrl + `getVideoUrl?${shopVideoUrl}`;

      try {
        const { data } = await axios.get(url);
        this.videoUrl = data;
		this.$emit('child-to-parent', data);
      } catch (error) {
        console.error('Failed to fetch video URL:', error);
      }
    }
  }
};
</script>

<style scoped>
</style>