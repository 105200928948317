<!-- App.vue -->
<template>
  <div id="app">
	  <div id="fullVideo" v-if="isFullVideoVisible" >
	    <iframe :src="videoUrl" frameborder="0" width="100%" height="100%"></iframe>
	    <button class="btn" @click="hideFullVideo">关闭</button>
	  </div>
    <div id="content">
      <race-item v-for="(item, index) in raceData" :key="index" :race-item="item" @child-to-parent="handleChildEvent"/>
    </div>
  </div>
</template>

<script>
import RaceItem from './RaceItem.vue';
import axios from 'axios';

export default {
  components: {
    RaceItem
  },
  data() {
    return {
      isFullVideoVisible: false,
      videoUrl: '',
      raceData: []
    };
  },
  async created() {
    const timestamp = Date.now();
    const num = Math.floor(Math.random() * 10 + 1);
    const loadRaceUrl = this.$apiUrl + `getTracks?url=https://online.rtn.tv/mobileserver/index.php/gateway/gettracks&dc=${timestamp}&custid=497&page=1&start=0&limit=25&callback=Ext.data.JsonP.callback${num}&referer=http://replays.robertsstream.com/`;

    try {
      const { data } = await axios.get(loadRaceUrl);
      this.raceData = data;
    } catch (error) {
      console.error('Failed to fetch race data:', error);
    }
  },  
  methods: {
    hideFullVideo() {
      this.isFullVideoVisible = false;
      this.videoUrl = '';
    },
	handleChildEvent(url) {
	  this.videoUrl = url;
	  this.isFullVideoVisible = true;
	}
  }
};
</script>

<style>
		body{
			background-color: black;
			margin: 0px;
			padding: 0px;
			width : 100%;
			height : 100%;
		}
		
		.left{
			width : 300px;
			border: 1px white solid;
			color: green;
			font-size: 10px;
			margin-top: 5px;
		}
		
		iframe{
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			width: 99.5%;
			height: 99.5%;
		}
		
		.btn{
			color: black;
			float: right;
		}
		
		#fullVideo{
			width : 100%;
			height : 100%;
		}
</style>