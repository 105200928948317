import axios from 'axios';

// GET请求
async function get(url, params) {
  try {
    const response = await axios.get(url, { params });
    return response.data;
  } catch (error) {
    console.error('GET请求错误:', error);
    throw error;
  }
}

// POST请求
async function post(url, data) {
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error('POST请求错误:', error);
    throw error;
  }
}

async function getList(apiUrl){
  axios({
      url:apiUrl,
      method:'GET',
      headers: {
          "Access-Control-Allow-Origin":"*"
      }
  })
  .then(res => {
      return res;
  })
  .catch(
      error => {
        console.log(error)
      }
  )
}

function postList(){
  axios({
      url:"http://127.0.0.1:8081/pda/pm/getNotificationFormList",
      data:{
                  "distinguish": 1,
                  "pageNum": 0,
                  "pageSize": 50
              },
      method:"POST",
      headers:{'Authorization': 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5MDAwMzcxNCIsInVzZXJuYW1lIjoiOTAwMDM3MTQifQ.stkb9z0rE85Mc3HdsC7spEiqObGVGtTkBZ-2MPPq7endv3o8CazF5bnga3XQMKiwD8JtGmJ5YkBlu7CYatsXbw'}
  }).then(res => {
      this.list = res.data.resp
      console.log(res.data.resp.rows);
  })
  .catch(
      error => {
          console.log(error)
      }
  )
}

export { get, post, getList };
