import VueRouter from 'vue-router'
import Demo from '../components/Demo.vue'
import Table from '../components/Table.vue'
import LeftTable from '../components/competition/LeftTable.vue'
import TableOne from '../components/TableOne.vue'
import Login from '../components/login/Login.vue'
import Video from '../components/Video.vue'
import Home from '../components/Home.vue'

const routes = [
		{
			path:"/",
			component: Video,
			meta: { requiresAuth: true }
		},
        {
            path:"/login",
            component: Login
        },
		{
		    path:"/video",
		    component: Video,
			meta: { requiresAuth: true }
		},
        {
            path:"/home",
            component: Home,
            children:[
                {
                    path:"userList",
                    component:Table
                },
                {
                    path:"userCreate",
                    component:TableOne
                }
            ]
        },
        {
            path:"/demo",
            component:Demo,
            children:[
                {
                    path:"table",
                    component:Table
                },
                {
                    path:"leftTable",
                    component:LeftTable
                },
                {
                    path:"tableOne",
                    component:TableOne
                }
            ]
        }
    ]

const router = new VueRouter({
    routes
})

// 在此处设置全局前置导航守卫
router.beforeEach((to, from, next) => {
  // const isLoggedIn = store.getters.isLoggedIn // 如果使用 Vuex

  // 或者直接从本地存储或其他方式检查登录状态
  const isLoggedIn = sessionStorage.getItem('loginInfo');
  console.log(isLoggedIn);

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 这个路由需要登录权限
    if (isLoggedIn == null) {
      next({ path: '/login' }) // 跳转到登录页
    } else {
      next() // 已经登录，继续访问目标路由
    }
  } else {
    next() // 无需登录权限，直接访问
  }
})

export default router