<template>
  <div class="vertical-table">
    <el-row v-for="(item, index) in tableData" :key="index" class="table-row">
      <el-col :span="4" class="table-title">
        <div >
          <p>{{ item.meetingName }}</p>
          <p>({{ item.location }}) </p>
        </div>
      </el-col>
      <el-col
      :span="12"
      v-for="(dataItem, dataIndex) in getFormattedData(item.races)"
      :key="'col-' + dataIndex"
      :class="{ 'table-data': true, disabled: dataItemIsEmpty(dataItem)}">
        <div class="data-wrapper">
          <p>{{ item.raceType }} {{ dataItem.raceNumber }}</p >
          <p>{{ dataItem.raceStartTime | formatTime}} </p >
          <p>
            <span v-for="(result,resIndex) in dataItem.results" key="resIndex">
              <span v-for="(resultItem,resItemIndex) in result" key="resItemIndex">
              {{ resultItem }}
              </span>
            </span>
          </p >
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '../../api/index.js';

export default {
  data() {
    return {
      tableData: []
    };
  },
  async created() {
    try {
      const data = await this.getData();
      this.tableData = data.meetings;
    } catch (error) {
      console.error('获取数据时发生错误:', error);
      // 处理错误
    }
  },
  filters: {
  formatTime(value) {
    const date = new Date(value);
    const formattedTime = date.toLocaleString('en-US', {
      timeZone: 'Asia/Shanghai',
      hour12: false,
      hour: 'numeric',
      minute: 'numeric'
    });
    return formattedTime;
  }
}
, methods: {
    getFormattedData(data) {
      const formattedData = [...data];
      const emptyDataCount = 12 - formattedData.length;
      if (emptyDataCount > 0) {
        for (let i = 0; i < emptyDataCount; i++) {
          formattedData.push({});
        }
      }
      return formattedData;
    },
    // 判断数据项是否为空函数 
    dataItemIsEmpty(data) {
      return Object.keys(data).length === 0;
    },
    async getData() {
      try {
        const data = await get('http://47.91.57.3:8081/getHorseData', '');
        return data;
      } catch (error) {
        console.error('获取数据时发生错误:', error);
        throw error;
      }
    }
  }
};
</script>


<style>
.vertical-table {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.table-row {
  display: flex;
  align-items: center;
}

.table-title {
  flex-basis: 30%;
  padding: 8px;
  font-weight: bold;
  background-color: #f5f7fa;
  border: 1px solid #ebeef5;
  height: 140px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-data {
flex-basis: 20%;
padding: 8px;
border: 1px solid #ebeef5;
background-color: #ffffff;
height: 140px;
width: 100px;
display: flex;
justify-content: center;
align-items: center;
transition: background-color 0.3s ease; /* 添加过渡效果 */
}

.table-data:not(.disabled):hover {
background-color: rgb(181, 244, 245); /* 鼠标悬停时的背景颜色 */
}

.data-wrapper {
text-align: center;
}

.disabled { /* 添加被禁止状态 */
background-color: #f5f7fa;
cursor: not-allowed; /* 更改光标类型为禁用 */
}
</style>