<template>
    <div>
      <el-row>
        <el-col :span="6">
          <el-menu
            default-active="dashboard"
            class="el-menu-vertical-demo"
            @select="handleMenuSelect"
          >
            <el-menu-item index="dashboard">仪表盘</el-menu-item>
            <el-submenu index="user">
              <template slot="title">用户管理</template>
              <el-menu-item index="home/userList">用户列表</el-menu-item>
              <el-menu-item index="home/userCreate">创建用户</el-menu-item>
            </el-submenu>
            <el-submenu index="order">
              <template slot="title">赛事管理</template>
              <el-menu-item index="orderList">订单列表</el-menu-item>
              <el-menu-item index="orderCreate">创建订单</el-menu-item>
            </el-submenu>
            <el-submenu index="product">
              <template slot="title">单据管理</template>
              <el-menu-item index="productList">商品列表</el-menu-item>
              <el-menu-item index="productCreate">创建商品</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-col>
        <el-col :span="18" :offset="1">
          <router-view></router-view>
        </el-col>
      </el-row>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      handleMenuSelect(index) {
        // 根据菜单项的index进行路由跳转
        this.$router.push(`/${index}`);
      },
    },
  };
  </script>
  
  <style scoped>
  .el-menu-vertical-demo {
    width: 200px;
  }
  
  .el-col {
    height: 100vh;
    overflow: auto;
  }
  
  .el-col:last-child {
    background-color: #f0f2f5;
    padding: 20px;
  }
  </style>
  