<template>
  <div>  
    <Input />
    <el-main>
    <el-table :data="tableData">
      <el-table-column prop="address" label="赛场地址" width="140">
      </el-table-column>
      <el-table-column prop="radio" label="比赛场次" width="120">
      </el-table-column>
      <el-table-column prop="startTime" label="比赛开始时间">
      </el-table-column>
      <el-table-column prop="second" label="倒计时">
      </el-table-column>
    </el-table>
  </el-main>
  </div>

</template>

<script>
  import Input from './Input.vue'

  export default {
    data() {
      const item = {
        startTime: '20:00',
        radio: 'R1',
        address: 'Vaal(ZAF)',
        second: '10s'
      };
      return {
        tableData: Array(20).fill(item)
      }
    },
    components: {
      Input
    }
  };
</script>
    