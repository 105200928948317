<template>
    <div>
        <el-input class="el-input" v-model="input" placeholder="请输入内容"></el-input>
        <el-button type="primary">搜索</el-button>
    </div>
</template>

<script>
    export default {
    data() {
        return {
        input: ''
        }
    }
    }
</script>

<style>
    .el-input{
        width: 200px;
    }
</style>